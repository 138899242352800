// PricingTier.tsx
import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';

interface PricingTierProps {
  title: string;
  monthlyCost: string;
  totalCost: string;
  ctaText: string;
  onCtaClick: () => void;
}

const PricingTier: React.FC<PricingTierProps> = ({
  title,
  monthlyCost,
  totalCost: originalPrice,
  ctaText,
  onCtaClick,
}) => {
  return (
    <Card sx={{ display: 'flex' }}>
      <CardContent sx={{ flex: '1 0 auto' }}>
        <Typography
          gutterBottom
          component="div"
          sx={{ fontWeight: 'bold', fontSize: '1.8rem' }}
        >
          {monthlyCost}
        </Typography>
      </CardContent>
      <Box
        sx={{
          paddingRight: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'right',
        }}
      >
        <Button
          size="large"
          onClick={onCtaClick}
          variant="contained"
          sx={{
            justifySelf: 'right',
            background: '#003366',
            width: '12rem',
            height: '3rem',
            fontWeight: 'bold',
          }}
        >
          {ctaText}
        </Button>
      </Box>
    </Card>
  );
};

export default PricingTier;

// ChoosePlan.tsx
import React, { useState } from 'react';
import PricingTier from './PricingTier';
import {
  Grid,
  Typography,
  Container,
  Card,
  CardContent,
  List,
  ListItem,
  Box,
  Button,
} from '@mui/material';
import { createStripeSession } from '../../redux/slices/teacherSlice';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../LibraryComponents/Spinner';
import checkmark from '../../Assets/checkmark.png';
import { Toast } from '../../LibraryComponents/Toast';
import { ContactUs } from '../../LibraryComponents/ContactUs';
import { LicenseKeyEntry } from '../../Pages/AccountSettings/Components/LicenseKeyEntry';

export const ChoosePlan: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accessToken, userId, refreshToken } = useSelector(
    (state: RootState) => state.auth,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [openLicenseKeyEntry, setOpenLicenseKeyEntry] = useState(false);

  const handle1MonthClick = async () => {
    setIsLoading(true);
    // const teacherRefresh = await dispatch(
    //   teacherRefreshLogin({ userId: userId, refreshToken}),
    // );
    // dispatch(loginRefresh(teacherRefresh.payload));

    const response = await dispatch(
      createStripeSession({ teacherId: userId as string, token: accessToken }),
    );
    setIsLoading(false);
    if (!response.payload) {
      setOpenToast(true);
      return;
    }
    window.location.href = response.payload;
  };

  const renderMessageWithContactUs = (message: string) => {
    if (!message.includes('{Contact Us}')) {
      return <>{message}</>;
    } else {
      const messageParts = message.split('{Contact Us}');
      return (
        <>
          {messageParts[0]} <ContactUs />
          {messageParts[1]}
        </>
      );
    }
  };

  const featureList = [
    'Course-long pacing guides',
    'Projects designed for hands-on learning',
    'Worksheets for active learning and practice',
    'Standards-aligned assessment question banks',
    'Library of pre-built assessments',
    'Teacher Tips and Tricks',
    'Lemoney Coaching Videos',
    'Elevated Customer Support',
    'Lemoney AI (Beta) ',
    'Key Words Vocab Bank (Coming Soon!)',
  ];

  return (
    <Container>
      {isLoading && <Spinner />}
      <Typography
        color="#003366"
        variant="h4"
        align="center"
        gutterBottom
        mt={4}
        mb={5}
        sx={{ fontWeight: 'bold' }}
      ></Typography>
      <Toast
        isOpen={openToast}
        severity="error"
        onClose={() => setOpenToast(false)}
        styles={{
          width: '100%',
          textAlign: 'center',
          top: 0,
          marginBottom: '2rem',
        }}
        errorMessage="Oops, there was a problem upgrading to premium, please try again! If the problem persists, please reach out to us."
      />
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Grid item marginRight={'4rem'}>
          <Card variant="outlined" sx={{ height: '95%' }}>
            <CardContent sx={{ borderTop: '6px solid #003366' }}>
              <Typography
                color="#003366"
                gutterBottom
                variant="h5"
                component="div"
                sx={{ fontWeight: 'bold' }}
              >
                Lemoney Learning Premium
              </Typography>
            </CardContent>
            <List>
              {featureList.map((feature, i) => (
                <ListItem key={i}>
                  <Box
                    component={'img'}
                    src={checkmark}
                    lang="img-lemmy"
                    width={20}
                    marginRight={'1rem'}
                  />
                  <Typography>{feature}</Typography>
                </ListItem>
              ))}
            </List>
            <PricingTier
              title=""
              monthlyCost="$19.99 / month"
              totalCost=""
              ctaText="Subscribe Now"
              onCtaClick={handle1MonthClick}
            />
          </Card>
          <Typography
            color="#003366"
            align="center"
            gutterBottom
            sx={{ fontWeight: 'bold' }}
          >
            {' '}
            <ContactUs /> for multiple licenses or district sales.
          </Typography>
        </Grid>
        <Grid item xs={3.5} md={3.5}>
          <Typography
            color="#003366"
            align="center"
            gutterBottom
            sx={{ fontWeight: 'bold' }}
          >
            If you already have a license key, enter it here!
          </Typography>
          <Button
            variant="contained"
            fullWidth
            data-testid="settings-enter-licence"
            sx={{ mt: '10px', background: '#003366' }}
            onClick={() => setOpenLicenseKeyEntry(true)}
          >
            Enter License Key
          </Button>
        </Grid>
      </Grid>

      <LicenseKeyEntry
        open={openLicenseKeyEntry}
        setOpenLicenseKeyEntry={setOpenLicenseKeyEntry}
        token={accessToken}
        teacherId={userId}
        refreshToken={refreshToken}
        renderMessageWithContactUs={renderMessageWithContactUs}
      />
    </Container>
  );
};
